import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Cta, Hero, History, InNumbers, OurValues, ProudOf } from 'src/components/LandingPage';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';

function AboutPage({ data, location }) {
  const {
    sanityBasicPage: {
      about: { hero, history, inNumbers, ourValues, proudOf, ctaOne },
      title,
      pageSeo,
    },
  } = data;

  const seo = {
    title: pageSeo?.title ?? title,
    description: pageSeo?.description,
    image: pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <Container fluid className="bg-shapes-banner px-0">
        <Hero hero={hero} />
      </Container>
      <History history={history} />
      <InNumbers inNumbers={inNumbers} />
      <OurValues ourValues={ourValues} />
      <ProudOf proudOf={proudOf} />
      <Cta cta={ctaOne} />
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    sanityBasicPage(id: { eq: $id }) {
      title
      pageType
      pageSeo {
        ...getPageSeo
      }
      about {
        hero {
          ...getHero
        }
        history {
          ...getHistory
        }
        inNumbers {
          ...getInNumbers
        }
        ourValues {
          ...getOurValues
        }
        proudOf {
          ...getProudOf
        }
        ctaOne {
          ...getCta
        }
        ctaTwo {
          ...getCtaWithAnimation
        }
      }
    }
  }
`;

AboutPage.propTypes = {
  data: PropTypes.shape({
    nodePage: PropTypes.shape({}),
  }).isRequired,
};

export default AboutPage;
